<template>
  <b-modal v-model="showModal"
           aria-label=""
           body-class="modal-form"
           modal-class="modal-style-one modal-background-white questionnaire"
           scrollable
           size="lg"
           title="Send Coupon"
           @hidden="clear"
           @ok="post"
           no-close-on-backdrop
  >

    <QuestionBase
        :errors="errors.coupon_type"
        :question="question.coupon_type"
        :valid="!!form.coupon_type">
      <InputRadioBasic
          v-model="form.coupon_type"
          @input="form.discount_service=null"
          :args="inputArgs.coupon_type"
          :options="couponTypeOptions"
          class="radio-options-wide"/>
    </QuestionBase>

    <QuestionBase
        v-if="form.coupon_type"
        :errors="errors.discount_service"
        :question="question.discount_service"
        :valid="!!form.discount_service">
      <InputRadioBasic
          v-model="form.discount_service"
          :args="inputArgs.discount_service"
          :options="discountOptions"
          class="radio-options-wide"/>
    </QuestionBase>

    <QuestionBase
        v-if="form.discount_service"
        :errors="errors.discount_percentage"
        :question="question.discount_percentage"
        :valid="!!form.discount_percentage">
      <InputStandardBasic
          v-model="form.discount_percentage"
          :args="inputArgs.discount_percentage"
      />
    </QuestionBase>


    <template v-if="form.coupon_type">

      <QuestionBase
          :errors="[...errors.client_one.name,
          ...errors.client_one.phone,
          ...errors.client_one.email
          ]"
          :question="question.client_one"
          :valid="!!(form.client_one.name && form.client_one.phone && form.client_one.email)"
          class="pb-0">


        <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">


          <div class="question question-multipart field-33">
            <InputStandardBasic v-model="form.client_one.name"
                                :args="inputArgs.client_one.name"
                                :errors="errors.client_one.name"/>
          </div>

          <div class="question question-multipart field-33">
            <InputStandardBasic v-model="form.client_one.phone"
                                :args="inputArgs.client_one.phone"
                                :errors="errors.client_one.phone"/>
          </div>

          <div class="question question-multipart field-33">
            <InputStandardBasic v-model="form.client_one.email"
                                :args="inputArgs.client_one.email"
                                :errors="errors.client_one.email"/>
          </div>
        </div>
      </QuestionBase>


      <template v-if="form.coupon_type === 'couple'">
        <QuestionBase
            :errors="[...errors.client_two.name, ...errors.client_two.phone, ...errors.client_two.email]"
            :question="question.client_two"
            :valid="!!(form.client_two.name && form.client_two.phone && form.client_two.email)"
            class="pb-0">

          <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">

            <div class="question question-multipart field-33">
              <InputStandardBasic v-model="form.client_two.name"
                                  :args="inputArgs.client_two.name"
                                  :errors="errors.client_two.name"/>
            </div>

            <div class="question question-multipart field-33">
              <InputStandardBasic v-model="form.client_two.phone"
                                  :args="inputArgs.client_two.phone"
                                  :errors="errors.client_two.phone"/>
            </div>

            <div class="question question-multipart field-33">
              <InputStandardBasic v-model="form.client_two.email"
                                  :args="inputArgs.client_two.email"
                                  :errors="errors.client_two.email"/>
            </div>


          </div>
        </QuestionBase>
      </template>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button
            class="btn btn-backwards white-border"
            @click="cancel">Cancel
        </button>
        <button
            class="btn btn-forwards"
            @click="ok">Send
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";
import InputRadioBasic from "@/components/common/other/inputs/InputRadioBasic";

export default {
  name: 'SendCouponModal',
  components: {InputRadioBasic, InputStandardBasic, QuestionBase},
  mixins: [toast],
  data() {
    return {
      form: {
        coupon_type: null,
        discount_service: null,
        discount_percentage: 100,
        client_one: {
          name: null,
          phone: null,
          email: null
        },
        client_two: {
          name: null,
          phone: null,
          email: null
        }
      },
      errors: {
        coupon_type: [],
        discount_service: [],
        discount_percentage: [],
        client_one: {
          name: [],
          phone: [],
          email: []
        },
        client_two: {
          name: [],
          phone: [],
          email: []
        }
      },
      question: {
        coupon_type: {
          title: 'Are you dealing with a single person or a couple?',
          subTitle: null,
          tip: null
        },
        discount_service: {
          title: 'Select a product to discount',
          subTitle: null,
          tip: null
        },
        discount_percentage: {
          title: 'Discount percentage',
          subTitle: 'This will now cost your client £XX.XX',
          tip: null
        },
        client_one: {
          title: 'Client One Details',
          subTitle: null,
          tip: null
        },
        client_two: {
          title: 'Client Two Details',
          subTitle: null,
          tip: null
        }
      },
      inputArgs: {
        coupon_type: {},
        discount_service: {},
        discount_percentage: {
          appendText: '%'
        },
        client_one: {
          name: {
            placeholder: 'e.g. Joe Jones',
            label: 'Full Name'
          },
          email: {
            placeholder: 'e.g. client_one@gmail.com',
            inputClass: 'field-100',
            label: 'Email'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100',
            label: 'Mobile Number'
          }
        },
        client_two: {
          name: {
            placeholder: 'e.g. Jannet Jones',
            label: 'Client Two Full Name'
          },
          email: {
            placeholder: 'e.g. client_two@gmail.com',
            inputClass: 'field-100',
            label: 'Email'
          },
          phone: {
            placeholder: 'e.g. 0790128101',
            inputClass: 'field-100',
            label: 'Mobile Number'
          }
        },
      },
      couponTypeOptions: [
        {
          label: 'Single Person',
          value: 'single'
        },
        {
          label: 'Couple',
          value: 'couple'
        }
      ],
      discountServiceSingleOptions: [
        {
          label: 'Will',
          value: 'WILL-S'
        },
        {
          label: 'LPA Health & Financial',
          value: 'LPAFH-S'
        },

      ],
      discountServiceCoupleOptions: [
        {
          label: 'Mirror Will',
          value: 'WILL-M'
        },
        {
          label: 'Mirror LPA Health & Financial',
          value: 'LPAFH-M'
        },
      ]
    }
  },
  props: {
    value: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    discountOptions() {
      if (this.form.coupon_type === 'single') return this.discountServiceSingleOptions
      else if (this.form.coupon_type === 'couple') return this.discountServiceCoupleOptions
      return []
    }
  },
  methods: {
    post(bvEvent) {
      bvEvent.preventDefault()
      this.clearErrors()
      http.post('send_coupon', this.form).then(
          response => {
            console.log(response.data)
            this.showModal = false
            this.toast('Coupon sent')
          }
      ).catch(
          error => {
            this.handleErrors(error.response.data)
          }
      )
    },
    clear() {
      this.clearForm()
      this.clearErrors()
    },
    clearForm() {
      this.form.coupon_type = null
      this.form.discount_service = null
      this.form.discount_percentage = 100
      this.form.client_one = {
        name: null,
        phone: null,
        email: null
      }
      this.form.client_two = {
        name: null,
        phone: null,
        email: null
      }
    },
    clearErrors() {
      this.errors.coupon_type = []
      this.errors.discount_service = []
      this.errors.discount_percentage = []
      this.errors.client_one = {
        name: [],
        phone: [],
        email: []
      }
      this.errors.client_two = {
        name: [],
        phone: [],
        email: []
      }
    },
    handleErrors(errors) {
      let keys = Object.keys(errors);

      for (let i in keys) {
        if (keys[i] === 'client_one') {
          let clientOneKeys = Object.keys(errors.client_one)
          for (let i in clientOneKeys) {
            this.errors.client_one[clientOneKeys[i]] = errors.client_one[clientOneKeys[i]]
            this.toastError(`${errors.client_one[clientOneKeys[i]][0]}`)

          }

        } else if (keys[i] === 'client_two') {
          let clientTwoKeys = Object.keys(errors.client_two)
          for (let i in clientTwoKeys) {
            this.errors.client_two[clientTwoKeys[i]] = errors.client_two[clientTwoKeys[i]]
            this.toastError(`${errors.client_two[clientTwoKeys[i]][0]}`)
          }
        } else {
          this.errors[keys[i]] = errors[keys[i]]
        }
      }
    }
  }
}
</script>
