<template>
  <div class="radio-options w-100 mt-2">
    <label v-if="args.label" class="sub-label">{{ args.label }}</label>

    <label v-for="(option, index) in options"
          :key="index + option"
          :class="{ 'active' : option.value === data, 'disabled' : args.disabled }"
          @click="data = options[index].value"
          class="radio">
      <span>{{ option.label }}</span>
    </label>

  </div>
</template>

<script>

export default {
  name: 'InputRadioBasic',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false
    },
    args: {
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
