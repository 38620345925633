<template>
  <div>
    <div class="hero-wrapper">
      <div class="hero">
        <div class="container">
          <h1 class="text-white">Welcome to<br>Kernel Coupon Tools</h1>
          <h3 class="text-white">Send a coupon.</h3>
        </div>
      </div>
    </div>
<!--    <Header/>-->

    <div class="container">


      <b-row>
        <b-col cols="6">

          <b-button @click="show.sendCoupon=true">Send Coupon</b-button>

        </b-col>

        <b-col cols="6">

        </b-col>
      </b-row>
    </div>


    <div class="container">
      <Footer/>
    </div>

    <SendCouponModal v-model="show.sendCoupon"/>
  </div>
</template>

<script>
import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import SendCouponModal from "@/views/introducer/coupons/SendCouponModal";


export default {
  name: 'Coupons',
  components: {
    SendCouponModal,
    Footer
  },
  data() {
    return {
      show: {
        sendCoupon: false
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    introducer() {
      return this.user?.is_introducer
    }
  },
  methods: {}
}
</script>
